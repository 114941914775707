let color = "#8299db";

let scaleW = 7;
let rotateW = 10;
let dRotate = 7;

export default [
    {
        name: 'tl', w: scaleW, h: scaleW, x: 0, y: 0, color: color, type: 'scale', shape: "rect", ref: "",
        action: 'scale', positiveX: false, positiveY: false,
        origin: (x, y, w, h) => {
            return {
                ox: x + w,
                oy: y + h
            }
        }
    },
    {
        name: 'tr', w: scaleW, h: scaleW, x: 0, y: 0, color: color, type: 'scale', shape: "rect", ref: "",
        action: 'scale',
        origin: (x, y, w, h) => {
            return {
                ox: x,
                oy: y + h
            }
        },
        positiveX: true,
        positiveY: false
    },
    {
        name: 'bl', w: scaleW, h: scaleW, x: 0, y: 0, color: color, type: 'scale', shape: "rect", ref: "",
        action: 'scale',
        origin: (x, y, w, h) => {
            return {
                ox: x + w,
                oy: y
            }
        },
        positiveX: false,
        positiveY: true
    },
    {
        name: 'br', w: scaleW, h: scaleW, x: 0, y: 0, color: color, type: 'scale', shape: "rect", ref: "",
        action: 'scale',
        origin: (x, y, w, h) => {
            return {
                ox: x,
                oy: y
            }
        },
        positiveX: true,
        positiveY: true
    },
    {
        name: 'ct', w: scaleW, h: scaleW, x: 0, y: 0, color: color, type: 'scale', shape: "rect", ref: "",
        action: 'scaleY',
        origin: (x, y, w, h) => {

        },
        positiveY: false,
        positiveX: true
    },
    {
        name: 'cr', w: scaleW, h: scaleW, x: 0, y: 0, color: color, type: 'scale', shape: "rect", ref: "",
        action: 'scaleX',
        positiveX: true,
        positiveY: true,
        origin: (x, y, w, h) => {

        }
    },
    {
        name: 'cb',
        w: scaleW,
        h: scaleW,
        x: 0,
        y: 0,
        color: color,
        type: 'scale',
        shape: "rect",
        action: 'scaleY',
        ref: "",
        positiveY: true,
        positiveX: true,
        origin: (x, y, w, h) => {

        }
    },
    {
        name: 'cl',
        w: scaleW,
        h: scaleW,
        x: 0,
        y: 0,
        color: color,
        type: 'scale',
        shape: "rect",
        action: 'scaleX',
        ref: "",
        positiveX: false,
        positiveY: true,
        origin: (x, y, w, h) => {

        }
    },
    {
        name: 'rtr',
        w: rotateW,
        h: rotateW,
        x: dRotate,
        y: -dRotate,
        color: "#c1ab13",
        type: 'rotate',
        shape: "circle",
        ref: "tr",
        action: 'rotate',
        positiveX: false,
        positiveY: false,
        origin: (x, y, w, h) => {
            return {
                ox: x + (w / 2),
                oy: y + (h / 2)
            }
        }
    },
    {
        name: 'rtl',
        w: rotateW,
        h: rotateW,
        x: -dRotate,
        y: -dRotate,
        color: "#c42e80",
        type: 'rotate',
        shape: "circle",
        ref: "tl",
        action: 'rotate',
        positiveX: false,
        positiveY: false,
        origin: (x, y, w, h) => {
            return {
                ox: x + (w / 2),
                oy: y + (h / 2)
            }
        }
    },
    {
        name: 'rbr',
        w: rotateW,
        h: rotateW,
        x: dRotate,
        y: dRotate,
        color: "#b710ef",
        type: 'rotate',
        shape: "circle",
        ref: "br",
        action: 'rotate',
        positiveX: false,
        positiveY: false,
        origin: (x, y, w, h) => {
            return {
                ox: x + (w / 2),
                oy: y + (h / 2)
            }
        }
    },
    {
        name: 'rbl',
        w: rotateW,
        h: rotateW,
        x: -dRotate,
        y: dRotate,
        color: "#dbb8c9",
        type: 'rotate',
        shape: "circle",
        ref: "bl",
        action: 'rotate',
        positiveX: false,
        positiveY: false,
        origin: (x, y, w, h) => {
            return {
                ox: x + (w / 2),
                oy: y + (h / 2)
            }
        }
    },
    // Mobile
    {
        name: 'rtl',
        w: rotateW,
        h: rotateW,
        x: -dRotate,
        y: -dRotate,
        color: "#c42e80",
        type: 'rotate',
        shape: "circle",
        ref: "tl",
        action: 'rotate',
        positiveX: false,
        positiveY: false,
        mobile: true,
        origin: (x, y, w, h) => {
            return {
                ox: x + (w / 2),
                oy: y + (h / 2)
            }
        }
    },
    {
        name: 'br',
        w: scaleW,
        h: scaleW,
        x: dRotate,
        y: dRotate,
        color: "#faca02",
        type: 'scale',
        shape: "circle",
        ref: "",
        action: 'scale',
        mobile: true,
        origin: (x, y, w, h) => {
            return {
                ox: x,
                oy: y
            }
        },
        positiveX: true,
        positiveY: true
    },

]