import {Day, init, Inputmask, Price} from "lct-lib";

import {createPinia} from "pinia";
import {createStore} from "../var/store";

import '../class2css/themes/default.json';

init({
    server: "https://sv.lct.vn",
    dbname: "teambannersports_com"
})


const pinia = createPinia();

const onCreateApp = (pageContext: any) => {
    const store = createStore();
    const {app} = pageContext;
    app.use(pinia);
    app.use(Price);
    app.use(Day);
    app.use(Inputmask);
    app.use(store);
}

export {onCreateApp}