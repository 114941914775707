function checkHit(box1, box2) {
    let {w:aW, h: aH, x: aX, y:aY, x2:aX2, y2:aY2} = box1;
    let {w:bW, h: bH, x: bX, y:bY, x2:bX2, y2:bY2} = box2;

    if(bX > aX && bX < aX2 && bY > aY && bY < aY2) {
        //console.log('c1');
        return true;
    }
    else if(bX2 > aX && bX2 < aX2 && bY > aY && bY < aY2) {
        //console.log("c2")
        return true;
    }
    else if(bX2 > aX && bX2 < aX2 && bY2 > aY && bY2 < aY2) {
        //console.log("c3")
        return true;
    }
    else if(bX > aX && bX < aX2 && bY2 > aY && bY2 < aY2) {
        //console.log("c4")
        return true;
    }
    //
    else if(bX > aX && bX < aX2 && bY < aY && bY2 > aY) {
        //console.log('c5');
        return true;
    }
    else if(bX2 > aX && bX2 < aX2 && bY < aY && bY2 > aY) {
        //console.log("c6")
        return true;
    }
    else if(bX < aX && bX2 > aX && bY > aY && bY < aY2) {
        //console.log("c7")
        return true;
    }
    else if(bX < aX && bX2 > aX && bY2 > aY && bY2 < aY2) {
        //console.log("c8")
        return true;
    } else {
        return false;
    }
}

export default checkHit