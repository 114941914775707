import _ from 'lodash';
let ChangeIdFieldSvgdoc = (svgdoc)=>{
    let svg = svgdoc.toString();
    let jqDoc = $(svgdoc);
    //defs > *, clipPath, linearGradient, radialGradient
    jqDoc.find("[id]").each((at, item)=>{
        //console.log(item.id);
        let idName = item.id;
        let reg = new RegExp(idName.toString(), 'g');
        let idReplace = "id" + _.random(100000).toString();
        svg = svg.replace(reg, idReplace);
    })
    return svg;
}

export {ChangeIdFieldSvgdoc}