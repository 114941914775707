import Artboard from "./svg-design/Artboard";

let state = ()=>({
    artboard: "",
    //model: "",
    states: [],
    currState: 0,
    //--------------
    fillColor: "#ffffff",
    strokeColor: "#000000",
    strokeWidth: 2,
    opacity: 1,
    //==========
    isAdmin: false,
    //=========
    popup: "",
    //grid
    grid: false,
    //===========
    history: [],
    currHistoryAt: -1,
    historySize: 10,
    //-------------------
    isAlignToArtboard: false
})

let mutations = {
    setArtboard(state, artboard) {
        state.artboard = artboard;
    },
    // setModel(state, value) {
    //     state.model = Object.assign({}, value);
    // },
    setStates(state, states) {
        state.states = states;
    },
    addState(state, aState) {
        state.states.push(aState);
        state.currState = state.states.length - 1;
    },
    removeState(state, at) {
        state.states.splice(at, 1);
        state.currState = at > 0 ? at - 1 : 0;
    },
    replaceState(state, aState) {
        state.states.splice(state.currState, 1, aState);
    },
    setCurrState(state, at) {
        state.currState = at;
    },
    setFillColor(state, value) {
        state.fillColor = value;
        Artboard.FillColor = value;
    },
    setStrokeColor(state, value) {
        state.strokeColor = value;
        Artboard.StrokeColor = value;
    },
    setStrokeWidth(state, value) {
        state.strokeWidth = value;
        Artboard.StrokeWidth = value;
    },
    setOpacity(state, value) {
        state.opacity = value;
        Artboard.Opacity = value;
    },
    setIsAdmin(state, value) {
        state.isAdmin = value;
    },
    setPopup(state, value) {
        state.popup = value;
        Artboard.Popup = value;
    },
    setGrid(state, value) {
        state.grid = value;
    },
    //History
    addHistory(state, svg) {
        let count = state.history.length;
        let size = state.historySize;
        let at = ++state.currHistoryAt;
        if(at >= size) {
            state.currHistoryAt = at = size - 1;
        }
        state.history.splice(at, count - at, svg);
    },
    changeHistoryAt(state, value) {
        state.currHistoryAt += value;
        let at = state.currHistoryAt;
        let count = state.history.length;
        let size = state.historySize;
        if(at < 0) {
            state.currHistoryAt = 0;
        } else if(at >= count) {
            --state.currHistoryAt;
        }
        //console.log(state.currHistoryAt);
    },
    //---------------
    changeAlignToArtboard(state, value) {
        state.isAlignToArtboard = value;
    }
}

let actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}