let state = ()=>({
    models: []
})

let mutations = {
    setModels(state, models) {
        state.models = models;
    },
}

let actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}