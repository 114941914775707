import axios from "axios";
let state = ()=>({
    assets: [],
})

let mutations = {
    setAssets(state, data) {
        state.assets = data;
    },
    addOne(state, asset) {
        state.assets.push(asset);
    },
    addMany(state, assets) {
        state.assets.push(...assets);
    },
    remove(state, index) {
        state.assets.splice(index, 1);
    }

}

let actions = {
    getAssets({commit}, options) {
        return axios.post('/svg-design/list-assets', {qr: options.qr, limit: options.limit, skip: options.skip}).then(result=>{
            commit('setAssets', result.data);
        }).catch(e=>{
            console.log(e);
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}