//import Vue from 'vue';
import Vuex from 'vuex';

//Vue.use(Vuex);

import DesignTool from '../pages/design-tool/@store.js';
import DesignToolSvgDesignCpnLibs from '../pages/design-tool/svg-design/cpn/libs/@store.js';
import DesignToolNewDesign from '../pages/design-tool/new-design/@store.js';


export function createStore() {
    return new Vuex.Store({
        modules: {
            DesignTool,DesignToolSvgDesignCpnLibs,DesignToolNewDesign,
        }
    })
}