import {isMobile, PointToLocal} from "../core/util";
import Artboard from "../Artboard";

let DrawRect = (event, artboard)=>{
    let draw = artboard.draw;
    let fillColor = Artboard.FillColor;
    let strokeColor = Artboard.StrokeColor;
    let mouseX = isMobile() ? event.touches[0].clientX : event.clientX;
    let mouseY = isMobile() ? event.touches[0].clientY : event.clientY;
    let p1 = PointToLocal(draw, draw, mouseX, mouseY);
    let rect = draw.rect()
        .fill(fillColor)
        .attr({"vector-effect": "non-scaling-stroke"})
        .opacity(Artboard.Opacity)
        .stroke({color: strokeColor, width: Artboard.StrokeWidth});

    $(window).off('touchend mouseup');
    $(window).on(isMobile()? "touchmove" : 'mousemove', (e)=>{
        let toX = isMobile() ? e.touches[0].clientX : e.clientX;
        let toY = isMobile() ? e.touches[0].clientY : e.clientY;
        let p2 = PointToLocal(draw, draw, toX, toY);
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        let mx = dx < 0 ? p1.x + dx : p1.x;
        let my = dy < 0 ? p1.y + dy : p1.y;
        rect.size(Math.abs(dx), Math.abs(dy)).move(mx, my);
    });
    $(window).on(isMobile() ? "touchend": 'mouseup', ()=>{
        if (rect.width() < 1) {
            rect.remove();
        }
        $(window).trigger('layer');
        $(window).off('touchmove mousemove');
    })
    artboard.tf.refreshModel();

}

export {DrawRect}