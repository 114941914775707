import _ from 'lodash';

//const {SVG, Matrix, PointArray} = require("@svgdotjs/svg.js");
import {Svg, Matrix, PointArray} from "@svgdotjs/svg.js";
import tickCfg from './cfg/tick-cfg';
import hookCfg from './cfg/hook-cfg';
import cursorCfg from "./cfg/cursor-cfg";
import {isMobile} from "./core/util";
import {
    alignBottom, alignBottomArtboard,
    alignH, alignHArtboard,
    alignLeft,
    alignLeftArtboard,
    alignRight,
    alignRightArtboard,
    alignTop, alignTopArtboard,
    alignV, alignVArtboard
} from "./utils/aligns";

class Transform {
    draw; //SVG element
    gWrap; //SVG element

    target; //Đối tượng SVG được chọn để thiết kế có thể là 1 g-selected hoặc clipart v.v
    targets = [];
    gSelected;


    gHook; //SVG element
    tl; tr; br; bl; ct; cr; cb; cl; rtr; rtl; rbr; rbl;

    rect; //SVG element

    gTick; //SVG element
    t_tl; t_tr; t_br; t_bl; t_ct; t_cr; t_cb; t_cl; t_rtr; t_rtl; t_rbr; t_rbl;

    //----
    isChange = false; //Check for history


    //===============Aligns===============
    constructor(draw) {
        this.draw = draw;
        //===================
        //Init the first tool case for undo or redo
        this.gWrap = this.draw.findOne('.transform-wrap');
        if (this.gWrap) {
            this.gTick = this.draw.findOne('.g-tick');
            for (let gt of tickCfg) {
                this[gt.name] = this.gTick.findOne(`.${gt.name}`);
            }
            this.gHook = this.draw.findOne('.transform-hook');
            for (let gh of hookCfg) {
                this[gh.name] = this.gHook.findOne(`.${gh.name}`);
            }
            this.rect = this.gHook.findOne('.hook-rect');
            this.removeTool();
        }

    }

    // setTarget(el, event = null) {
    //     this.target = el;
    //     this.removeTool();
    //     if(el !== null ){
    //         this.createTool(event); //mouse down init
    //         $(window).trigger('set-target');
    //     }
    // }

    setTarget(elm , event = null) {
        if (!elm) {
            this.removeTool();
            return;
        }
        //=============
        let els = [];
        if(_.isArray(elm)) {
            els = elm;
        } else {
            els.push(elm);
        }
        if(event && event.shiftKey && this.targets.length) {
            els.push(...this.targets);
        }
        //===============
        if(els.length === 1) {
            this.setSingleTarget(els[0], event);
        } else if(els.length > 1) {
            this.setMultiplyTarget(els, event);
        }
    }

    setMultiplyTarget(els, event) {
        this.targets = els;
        let targets = this.targets;
        let draw = this.draw;

        this.removeTool();

        let gSelected = this.gSelected = draw.group().addClass('g-selected');
        let at = els[0].position();
        targets = _.sortBy(targets, (o)=>{ //sắp xếp lại theo thứ tự
            return o.position();
        })
        for (let c of targets) {
            c.toParent(gSelected);
        }
        draw.removeElement(gSelected).add(gSelected, at);
        //this.tf.setTarget(gSelected, event);
        this.target = gSelected;

        this.createTool(event);

        $(window).trigger('set-target').trigger('layer');

    }
    setSingleTarget(el, event) {
        this.target = el;
        this.targets = [el];
        this.removeTool();
        if(el !== null ){
            this.createTool(event); //mouse down init
            $(window).trigger('set-target');
        }
    }

    removeTool() {
        this.removeHookTick();
        if (this.gWrap) {
            let gWrap = this.gWrap;
            let child = gWrap.first();
            let position = gWrap.position();
            gWrap.ungroup();
            if(child && child.hasClass('g-selected')) {
                let c = child.children();
                child.ungroup();
                for (let i = c.length - 1; i >= 0; i--) {
                    this.draw.removeElement(c[i]).add(c[i], position);
                }
                $(window).trigger('layer');
            } else {
                this.draw.removeElement(child).add(child, position);
            }
            this.gWrap = null;


        }
    }

    removeHookTick(){
        if (this.gHook) {
            this.rect.remove();
            this.rect = null;
            this.gHook.remove();
            this.gHook = null;
            for (let cfg of hookCfg) {
                this[cfg.name] = null;
            }
        }
        if (this.gTick) {
            this.gTick.remove();
            this.gTick = null;
            for (let cfg of tickCfg) {
                this[cfg.name] = null;
            }
        }
        //event
        $(window).off('touchend mouseup');
    }

    //remove taget
    removeTarget() {
        this.removeHookTick();
        if(this.target) {
            this.target.remove();
            this.target = null;
        }
        if(this.gWrap) {
            this.gWrap.remove();
            this.gWrap = null;
        }
        $(window).trigger('layer');
        $(window).trigger('add-history');
    }

    createTool(event = null) { // van la su kien mouse down

        let draw = this.draw;
        let target = this.target;
        let transform = target.transform();
        let {translateX, translateY, skewX, skewY, shear, scaleX, scaleY, rotate} = transform;
        let position = target.position();

        let gWrap = this.gWrap = draw.group().addClass('transform-wrap transform');
        let gHook = this.gHook = draw.group().addClass('transform-hook transform');

        let {x, y, w, h} = target.bbox();
        gWrap.transform(transform);
        target.toParent(gWrap);

        draw.removeElement(gWrap).add(gWrap, position);
        //gWrap.remember('at', position);

        //tick
        this.gTick = gWrap.group().addClass('g-tick');
        for (let tick of tickCfg) {
            let p = tick.p(x, y, w, h);
            this[tick.name] = this.gTick.circle(0.01,0.01).move(p.x, p.y).addClass(tick.name);
        }
        //mask
        //this.mask = gWrap.rect(w, h).move(x, y).fill("rgba(255,255,255,0)")
        //    .stroke({color: "#3668fa", width: 1,})
        //    .attr('vector-effect', 'non-scaling-stroke');

        this.moveHook();
        //event
        this.eventHook(event);
    }

    refresh() {
        let target = this.target;
        if(!target) return;
        let {x, y, w, h} = target.bbox();
        for (let tick of tickCfg) {
            let p = tick.p(x, y, w, h);
            this[tick.name].move(p.x, p.y);
        }
        this.moveHook();
        //this.removeTool();

    }

    getRatio() {
        let draw = this.draw;
        //Tính tỉ lệ zoom
        let svgElm = $('#artboard svg');
        let svgW = svgElm.width();
        let svgH = svgElm.height();
        let viewbox = draw.viewbox();
        //console.log(viewbox);
        let ratioW = viewbox.w/svgW;
        let ratioH = viewbox.h/svgH;
        return {ratioW, ratioH};
    }

    moveHook() {
        let gHook = this.gHook;
        let draw = this.draw;
        let {rotate, scaleX, scaleY} = this.gWrap.transform();
        let gWrapRbox = this.gWrap.rbox(draw);
        this.drawRect();

        //Tính tỉ lệ zoom
        let {ratioW, ratioH} = this.getRatio();


        for (let cfg of hookCfg) {
            if((isMobile() && !cfg.mobile) ||(isMobile() === false && cfg.mobile)) {
                continue;
            }
            let {name, w, h, x, y, type, action, shape, color, ref, cursor} = cfg;
            x = isMobile() ? x * 2.5 * ratioW : x * ratioW;
            y = isMobile() ? y * 2.5 * ratioH : y * ratioH;

            w = isMobile()? 50 : w;
            h = isMobile()? 50 : h;


            let hook = this[name];
            if(!hook) {
                hook = this[name] = gHook[shape](w * ratioW, h * ratioH).fill("white")
                    .stroke({color, width: ratioW}).addClass(name);
            }

            let rbox = type === 'rotate' ? this["t_" +ref].rbox(draw) : this["t_" + name].rbox(draw);
            let mx = scaleX > 0 ? rbox.x + x : rbox.x - x;
            let my = scaleY > 0 ? rbox.y + y : rbox.y - y;
            hook.center(mx, my);
            if(type === 'rotate' || cfg.mobile) {
                hook.transform({rotate: rotate, ox: rbox.x, oy: rbox.y});
                hook.opacity(0);
            }
            if (isMobile()) {

                hook.fill(color).stroke({color: "#cccccc"}).opacity(.5);

            }

            //cursor
            let mouseStyle = this.getCursor(type, rbox, gWrapRbox);
            if(hook.css('cursor') !== mouseStyle) {
                hook.css('cursor', mouseStyle);
            }
        }
    }
    drawRect() {
        let draw = this.draw;
        let gHook = this.gHook;
        let p1 = this.t_tl.rbox(draw);
        let p2 = this.t_tr.rbox(draw);
        let p3 = this.t_br.rbox(draw);
        let p4 = this.t_bl.rbox(draw);

        let pointArray = new PointArray([[p1.cx, p1.cy], [p2.cx, p2.cy], [p3.cx, p3.cy], [p4.cx, p4.cy], [p1.cx, p1.cy]]);
        if(this.rect) {
            this.rect.clear();
            this.rect.plot(pointArray);
        } else {
            this.rect = gHook.polyline(pointArray).fill("none")
                .stroke({color: "#3668fa", width: this.getRatio().ratioW,}).addClass('hook-rect');
        }

    }

    getCursor(type, hook, gWrap) {
        let dx =  hook.x - gWrap.cx;
        let dy =  hook.y - gWrap.cy;
        let deg = 90 - (Math.atan2(dx, dy) * 180 / Math.PI);
        deg = deg >= 0 ? deg : 360 - Math.abs(deg);
        let oc = 360 / 16;

        for (let cfg of cursorCfg) {
            let dd = deg >= 360 - oc ? 360 - deg : deg;
            if(dd >= cfg.deg - oc && dd < cfg.deg + oc) {
                if(type === 'scale') {
                    return cfg.scale;
                }
                if(type === 'rotate') {
                    return `url('${cfg.rotate}') ${cfg.ox} ${cfg.oy}, default`;
                }
            }
        }
    }

    move(event) {
        let gWrap = this.gWrap;
        let draw = this.draw;

        let {x, y, w, h} = gWrap.bbox();
        let ma = gWrap.node.getScreenCTM().inverse();
        let p1 = draw.node.createSVGPoint();
        p1.x = isMobile() ? event.touches[0].clientX : event.x;
        p1.y = isMobile() ? event.touches[0].clientY : event.y;
        p1 = p1.matrixTransform(ma); //First point tai tao do element

        let m1 = gWrap.matrix();

        $(window).on(isMobile() ? 'touchmove': 'mousemove', (e) => {
            //console.log(e.touches[0].clientX);
            let p2 = draw.node.createSVGPoint();
            p2.x = isMobile() ? e.touches[0].clientX : e.clientX;
            p2.y = isMobile() ? e.touches[0].clientY : e.clientY;
            p2 = p2.matrixTransform(ma);
            let dx = p2.x - p1.x;
            let dy = p2.y - p1.y;
            let m2 = new Matrix({translate: [dx, dy], ox: x, oy: y});
            let m3 = m1.multiply(m2);

            gWrap.transform(m3);
            this.moveHook();

            this.isChange = true;

        })
    }

    eventHook(event) { //MouseDown
        let draw = this.draw;
        let target = this.target;
        let gWrap = this.gWrap;
        //Move
        if(event) this.move(event); //Đầu tiên
        gWrap.on(isMobile() ? 'touchstart': 'mousedown', (e) => {
            this.move(e);
        })

        //Hook
        for (let cfg of hookCfg) {
            let hook = this[cfg.name];
            if(!hook) continue;
            hook.on(isMobile() ? 'touchstart' : 'mousedown', (e) => {
                let ma = gWrap.node.getScreenCTM().inverse();
                let p1 = draw.node.createSVGPoint();
                p1.x = isMobile() ? e.touches[0].clientX : e.clientX;
                p1.y = isMobile() ? e.touches[0].clientY : e.clientY;
                p1 = p1.matrixTransform(ma); //First point tai tao do element
                let bbox = gWrap.bbox();
                let rbox = gWrap.rbox(draw);
                let w = bbox.width;
                let h = bbox.height;
                let x = bbox.x;
                let y = bbox.y;
                let cx = bbox.cx;
                let cy = bbox.cy;
                let {scaleY, scaleX, rotate, skewX, skewY, translateX, translateY, originX, originY} = gWrap.transform();
                let m1 = gWrap.matrix();
                //Deg
                let rdx1 = p1.x - cx;
                let rdy1 = p1.y - cy;
                let deg1 = Math.atan2(rdx1, rdy1) * 180 / Math.PI;

                $(window).on(isMobile() ? 'touchmove' : 'mousemove', (e) => {
                    let p2 = draw.node.createSVGPoint();
                    p2.x = isMobile() ? e.touches[0].clientX : e.clientX;
                    p2.y = isMobile() ? e.touches[0].clientY : e.clientY;
                    p2 = p2.matrixTransform(ma);
                    let dx = (cfg.positiveX) ? p2.x - p1.x : p1.x - p2.x;
                    let dy = (cfg.positiveY) ? p2.y - p1.y : p1.y - p2.y;

                    //rotate
                    let rdx2 = p2.x - cx;
                    let rdy2 = p2.y - cy;
                    let deg2 = Math.atan2(rdx2 , rdy2) * 180 / Math.PI;
                    let deg = deg1 - deg2;
                    if(scaleY < 0 || scaleX < 0) deg = -deg;

                    let toScaleX = ((w + dx) / w);
                    let toScaleY = (h + dy) / h;

                    //console.log(deg);

                    if (cfg.action === 'scaleX') {
                        let ox = cfg.positiveX ? x : x + w;
                        let oy = cfg.positiveY ? y : y;
                        //gWrap.transform({scaleX: toScaleX, rotate, scaleY, ox, oy});
                        let m2 = new Matrix({scaleX: toScaleX, ox, oy});
                        let m3 = m1.multiply(m2);
                        gWrap.transform(m3);
                    }
                    if (cfg.action === 'scaleY') {
                        let ox = cfg.positiveY ? x : x;
                        let oy = cfg.positiveY ? y : y + h;
                        //gWrap.transform({scaleX: toScaleX, rotate, scaleY, ox, oy});
                        let m2 = new Matrix({scaleY: toScaleY, ox, oy});
                        let m3 = m1.multiply(m2);
                        gWrap.transform(m3);
                        //gWrap.transform({scaleY: toScaleY, scaleX, origin: cfg.origin});
                    }
                    if (cfg.action === 'scale') {
                        let {ox, oy} = cfg.origin(x, y, w, h);
                        let m2;
                        if(e.shiftKey) {
                            m2 = new Matrix({scaleY: toScaleY, scaleX: toScaleY, ox, oy});
                        } else {
                            m2 = new Matrix({scaleY: toScaleY, scaleX: toScaleX, ox, oy});
                        }
                        let m3 = m1.multiply(m2);
                        gWrap.transform(m3);
                    }
                    if (cfg.action === 'rotate') {
                        //let {ox, oy} = cfg.origin(x, y, w, h);
                        //console.log(deg);
                        let m2 = new Matrix({rotate: deg, ox:rbox.cx, oy: rbox.cy});
                        let m3 = m2.multiply(m1);
                        gWrap.transform(m3);
                        //gWrap.transform({rotate: deg, ox, oy, scaleX, scaleY});
                    }

                    this.moveHook();

                })
            })
        }
        $(window).on(isMobile() ? 'touchend': 'mouseup', () => {
            $(window).off('touchmove mousemove');
            if(this.isChange) {
                this.isChange = false;
                $(window).trigger('add-history');
                //console.log('trigger add history');
            }
        })
    }

    refreshModel() {
        this.draw.findOne('.mask').front();
        this.draw.findOne('.model').back();
    }

    //==================
    front() {
        if(this.target && this.gWrap) {
            let gWrap = this.gWrap;
            gWrap.front();
            this.refreshModel();
            $(window).trigger('layer');
            $(window).trigger('add-history');

        }
    }
    back() {
        if(this.target && this.gWrap) {
            let gWrap = this.gWrap;
            gWrap.back();
            gWrap.forward();
            this.refreshModel();
            $(window).trigger('layer');
            $(window).trigger('add-history');
        }
    }
    forward() {
        if(this.target && this.gWrap) {
            let gWrap = this.gWrap;
            gWrap.forward();
            this.refreshModel();
            $(window).trigger('layer');
            $(window).trigger('add-history');
        }
    }
    backward() {
        if(this.target && this.gWrap) {
            let gWrap = this.gWrap;
            if(gWrap.position() > 1 ) {
                gWrap.backward();
                this.refreshModel();
                $(window).trigger('layer');
                $(window).trigger('add-history');
            }
        }
    }
    //Swap index
    changeIndex(from, to) {
        let draw = this.draw;
        let el = draw.get(from);
        draw.removeElement(el).add(el, to);
        $(window).trigger('layer');
        $(window).trigger('add-history');
    }
    //==================
    alignLeft(isToArtboard = false) {
        if(!isToArtboard)
            alignLeft(this.draw, this.gWrap, this.target, this.getRatio());
        else
            alignLeftArtboard(this.draw, this.getRatio());
        this.refresh();
    }
    alignRight(isToArtboard = false) {
        if(!isToArtboard)
            alignRight(this.draw, this.gWrap, this.target, this.getRatio());
        else
            alignRightArtboard(this.draw, this.getRatio());
        this.refresh();
    }
    alignTop(isToArtboard = false) {
        if(!isToArtboard)
            alignTop(this.draw, this.gWrap, this.target, this.getRatio());
        else
            alignTopArtboard(this.draw, this.getRatio());
        this.refresh();
    }
    alignBottom(isToArtboard) {
        if(!isToArtboard)
            alignBottom(this.draw, this.gWrap, this.target, this.getRatio());
        else
            alignBottomArtboard(this.draw, this.getRatio());
        this.refresh();
    }
    alignH(isToArtboard) {
        if(!isToArtboard)
            alignH(this.draw, this.gWrap, this.target, this.getRatio());
        else
            alignHArtboard(this.draw, this.getRatio());
        this.refresh();
    }
    alignV(isToArtboard) {
        if(!isToArtboard)
            alignV(this.draw, this.gWrap, this.target, this.getRatio());
        else
            alignVArtboard(this.draw, this.getRatio());
        this.refresh();
    }

    //============Group
    group() {
        if(this.target && this.gWrap) {
            let gWrap = this.gWrap;
            let child = gWrap.first();
            if(child.hasClass('g-selected')) {
                child.removeClass('g-selected');
            }
            $(window).trigger('add-history');
        }
    }
    ungroup() {
        if(this.target && this.gWrap) {
            let gWrap = this.gWrap;
            let child = gWrap.first();
            if(!child.hasClass('g-selected')) {
                child.addClass('g-selected');
            }
            $(window).trigger('add-history');
        }
    }
    //======Copy
    copy() {
        if(this.target && this.gWrap) {
            this.removeTool();
            let copys = [];
            for (let i of this.targets) {
                let cp = i.clone();
                let ma  = new Matrix({rx: 10, ry: 0});
                cp.transform(ma.multiply(cp.matrix()));
                cp.addTo(this.draw);
                copys.push(cp);
            }
            this.refreshModel();
            this.setTarget(copys);

            $(window).trigger('add-history');
            $(window).trigger('layer');
        }
    }

}

export default Transform;