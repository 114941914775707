import axios from "axios";
import {SVG} from "@svgdotjs/svg.js";

let loadSVGContent = (url)=>{
    return axios.get(url).then(result=>{
        let data = result.data;
        let first = data.search('<svg');
        let end = data.search("</svg>");
        data = data.substr(first);
        let draw = SVG(data);
        let children = draw.children();
        if (children.length && children.length > 1) {
            let g = draw.group();
            for (let c of children) {
                c.toParent(g);
            }
            return Promise.resolve(g);
        } else {
            return Promise.resolve(children[0]);
        }

    }).catch(e=>{
        return Promise.reject(e);
    })
}

export default loadSVGContent;