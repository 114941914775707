import Transform from "./transform";
//const {SVG, Matrix} = require("@svgdotjs/svg.js");
import {Matrix, SVG} from "@svgdotjs/svg.js";
import _ from 'lodash';
import checkHit from "./core/check-hit";
import {isMobile, isSafariMobile} from "./core/util";
import loadSVGContent from "./core/loadSVG";
import {DrawRect} from "./utils/draw-rect";
import {DrawEllipse} from "./utils/draw-ellipse";
import {DrawLine} from "./utils/draw-line";
import {DrawPolygon} from "./utils/draw-polygon";
import md5 from "md5";
import axios from "axios";
import {each} from 'async';
import {ChangeIdFieldSvgdoc} from "./core/change-Id-field-svgdoc";

class Artboard {
    draw;
    frame;
    model;
    mask;
    tf;
    selectRect;
    gSelected;
    maxW = 1000;
    maxH = 600;

    noTransform = ['transform', 'model', 'mask', "locked"];

    static Action = "select";

    //-------------------
    modelInfo = {};
    //----------------
    static Zoom = "fit";
    static Popup = "";
    //color---------
    static FillColor = "#fff";
    static StrokeColor = "#000000";
    static StrokeWidth = 2;
    static Opacity = 1;
    //Draw
    drawPolygon;

    constructor(options) {
        if ($(window).outerWidth() > 1000) {
            Artboard.Popup = "libs";
        }
        try {
            let {el, svg, model} = options;
            if (options.svg) {
                this.draw = SVG(svg).addTo(el);
                this.model = this.draw.findOne('.model');
                this.mask = this.draw.findOne('.mask');
                this.setZoom('fit');
                $(window).trigger('layer');
                this.onready();
            } else {
                this.draw = SVG().size("100%", "100%").viewbox(0, 0, this.getWindowSize().w, this.getWindowSize().h)
                    .css({'background-color': 'white'}).attr({preserveAspectRatio: 'none'}).addTo(el);
                //Model
                this.model = this.draw.group();
                this.model.addClass('model').css("pointer-events", "none");
                this.mask = this.draw.group();
                this.mask.addClass('mask').css("pointer-events", "none");
                this.setModel(model);
                $(window).on('model', this.onready);
            }
            this.modelInfo = model;
            this.draw.data('info', model);
            //this.test();
            this.mountEvent();
            this.tf = new Transform(this.draw);
            //=====================================
            // $(window).on("resize", () => {
            //     this.setZoom(this.zoom);
            // });
            //=====================================
            //Key
            $(window).on('keyup', (e)=>{
                if(e.keyCode === 8 || e.keyCode === 46) {
                    if(e.target.tagName === 'BODY') {
                        this.remove();
                    }

                }
            });
            $(window).on('keydown', (e) => {
                //console.log(e);
                if ((e.ctrlKey || e.metaKey) && e.which === 67) {
                    this.tf.copy();
                }
            });



        } catch (e) {
            console.log(e);
        }
    }

    destroy() {
        $(window).off('keyup keydown mouseup mousedown');
    }

    onready() {
        $(window).trigger("artboard");
        $(window).off('model');
        //$(window).on('add-history', this.addHistory);
    }

    removeDraw() {
        this.draw.remove();
        this.draw = null;
        this.destroy();
    }

    getWindowSize() {
        let screenSize = $(window);
        let w = screenSize.width();
        let toolTopH = $(".tool-top").height();
        let bottomH = $('.bottom-on-mobile').is(':hidden') ? 0 : $(".bottom-on-mobile").height();

        //let h = w < 880 ? screenSize.height() - 210 : screenSize.height() - 140;
        let h = screenSize.height() - (toolTopH + bottomH);

        return {w, h};
    }

    refreshModel() {
        this.mask.front();
        this.model.back();
    }

    setModel(model) {
        let draw = this.draw;
        //this.modelInfo = model;
        let modelReady = false;
        let maskReady = false;
        loadSVGContent(model.back.url).then(result=>{
            let model = result.addTo(this.model);
            this.setZoom(Artboard.Zoom);
            modelReady = true;
            if(maskReady) $(window).trigger("model");
        }).catch(err=>{
            console.log(err);
        });
        //mask
        loadSVGContent(model.mask.url).then(result=>{
            let mask = result.addTo(this.mask);
            maskReady = true;
            if(modelReady) $(window).trigger("model");
        }).catch(err=>{
            console.log(err);
        })
    }

    setZoom(value, panelWidth = 0) {
        Artboard.Zoom = value;
        if(value === 'fit') {
            this.fitOnScreen(panelWidth);
        } else {
            let draw = this.draw;
            let model = this.model;
            let mBox = model.rbox(draw);
            let dBox = draw.viewbox();
            let winW = this.getWindowSize().w;
            let winH = this.getWindowSize().h;
            let ratio = Number(value);

            let x = dBox.x;
            let y = dBox.y;

            this.draw.viewbox(x, y, winW / ratio, winH / ratio);
        }

    }

    fitOnScreen(panelWidth) {
        let winW = this.getWindowSize().w;
        let winH = this.getWindowSize().h;
        let pad = winW < 700 ? 12 : 100;
        let panelW = Artboard.Popup && winW > 700 ? panelWidth : 0;
        let draw = this.draw;
        let model = this.model;
        //console.log(model, draw);
        let mBox = this.model.rbox(draw);
        let ratio = Math.min((winW - panelW - pad) / mBox.w, (winH - pad) / mBox.h);
        let mScaleW = mBox.w * ratio;
        let mScaleH = mBox.h * ratio;
        let x = ((winW - mScaleW - panelW) / 2) / ratio;
        let y = ((winH - mScaleH) / 2) / ratio;
        this.draw.viewbox(-x, -y, winW / ratio, winH / ratio);
    }

    setAction(action) {
        Artboard.Action = action;
    }
    // setPopup(name) {
    //     Artboard.Popup = name;
    // }

    //------------------------------------------ADD ELEMENT-------
    addElement(type, options) {
        switch (type) {
            case 'image':
                this.addImage(options);
                break;
        }
        this.mask.front();
        this.model.back();
    }
    addImage(options) {
        let img = this.draw.image(options.url).addClass('asset');
        img.on('load', ()=>{
            let {w: iW, h: iH, cx: icx, cy: icy} = img.bbox();
            let {w: mW, h: mH} = this.model.bbox();
            let s;
            if(options.isBg) {
                s = mH/iH;
                let currBG = this.draw.findOne('.background.locked');
                if(currBG) {
                    currBG.remove();
                }
                img.back();
                this.refreshModel();
                img.addClass('background locked');
                //this.refreshModel();
                let ma = new Matrix({scaleX: s, scaleY: s, ox: 0, oy: 0, px: 0, py: 0});
                img.transform(ma.multiply(img.matrix()));
            } else {
                s = iW > iH ? 100/iW : 100/iH;
                //img.center(mW/2, mH/2);
                //img.scale(s, s);
                let ma = new Matrix({scaleX: s, scaleY: s, ox: icx, oy: icy, px: mW/2, py: mH/2});
                img.transform(ma.multiply(img.matrix()));
            }

            $(window).trigger('layer');
            $(window).trigger('add-history');
        })
    }
    //------------------------------------------
    exportSVG() {
        this.tf.removeTool();
        let {w, h } = this.model.bbox();
        this.draw.viewbox(1, 1, w - 1, h - 1);
        let data = this.draw.svg();
        this.setZoom(Artboard.Zoom);
        return data;
    }
    exportSVGReplaceAssets(exportW, exportH) {
        return new Promise((resolve, reject) => {
            let svg = SVG(this.exportSVG());
            //let svg = this.draw;
            let list = svg.find(".asset");
            each(list, (img, cb)=>{
                let href = $(img.node).attr("xlink:href") ? $(img.node).attr("xlink:href"): $(img.node).attr("href");
                //Check if img is the file of customer uploaded
                if(_.includes(href, "lct-designs-customer-upload")) {
                    $('.list-file-customer-upload').append(`<a href="${href}" target="_blank"><img src="${href}" width="50px">Download</a><br>`);
                    cb();
                    return;
                }

                if(href === undefined) {
                    cb();
                    return;
                }

                let match = href.match(/(.*)\/(.*)(.png)/);
                let name = md5(match[2]);
                let url = `${match[1]}/${name}.svg`;
                axios.get(url).then((result)=>{
                    let {x: ix, y: iy, w: iw, h: ih} = img.bbox();
                    let data = result.data;
                    let firstPos = data.search('<svg');
                    data = data.substr(firstPos);

                    data = ChangeIdFieldSvgdoc(data);

                    let elmSvgDoc = SVG(data);
                    elmSvgDoc.size(iw, ih);

                    let elm = svg.group();
                    elm.add(elmSvgDoc);

                    let {x: ex, y: ey, w: ew, h: eh} = elmSvgDoc.viewbox();

                    let ratio = iw/ew;
                    let imgMa = img.matrix();
                    let elmMa = elm.matrix();
                    elm.transform(imgMa.multiply(elmMa));
                    img.replace(elm);
                    cb();
                }).catch(e => {
                    //console.log(href);
                    cb(e);
                })
            }, (err)=>{
                if(err) {
                    console.log(err);
                    reject(err);
                }
                else {
                    let vb = svg.viewbox();
                    let ratio = Number(exportW * 72) / vb.w;
                    svg.width(vb.w * ratio);
                    svg.height(vb.h * ratio);
                    let svgDoc = svg.svg();
                    svg.remove();
                    svg = null;
                    resolve(svgDoc);
                }
            });
        })
    }


    openFileSVG(data) {
        let s1 = SVG(data);
        let vb = s1.viewbox();
        let s1W = vb.w > 0 ? vb.w : s1.width();
        let s1H = vb.h > 0 ? vb.h : s1.height();

        let scale;
        scale = s1W/s1H > this.maxW/this.maxH ? this.maxW/s1W : this.maxH/s1H;
        let w = s1W * scale;
        let h = s1H * scale;

        this.tf.removeTool();
        this.draw.clear();
        s1.each((i, children)=>{
            let c = children[i];
            //c = c.toParent(this.draw);
            this.draw.add(c);
            let m1 = c.matrix();
            let m2 = new Matrix({scaleX: scale, scaleY: scale, ox: 0, oy: 0});
            let m3 = m1.multiply(m2);
            c.transform(m3);
        });

        this.draw.size(w,h).viewbox(0,0, w, h);

    }

    //Add assets -------------------------
    addAsset(asset) {
        let w = asset.width();
        let h = asset.height();
        let t = w > h ? w : h;
        let scale = 200/t;
        this.draw.add(asset);
        let m1 = asset.matrix();
        let m2 = new Matrix({scaleX: scale, scaleY: scale, ox: this.getWidth()/2, oy: this.getHeight()/2});
        let m3 = m1.multiply(m2);
        asset.transform(m3);
    }
    remove() {
        this.tf.removeTarget();
    }
    //-------------------------------------


    //Lấy chiều rộng của draw
    getWidth() {
        return this.draw.width();
    }
    getHeight() {
        return this.draw.height();
    }

    test(){
        this.draw.rect(200, 100).move(100,100).fill('#1d53dc').rotate(45);
        this.draw.circle(100).move(350, 100).fill("#ff0");
        this.draw.rect(200, 100).move(100, 400).fill('#384').skew(20, 10).rotate(30);
        this.draw.rect(200, 100).fill('#a02054').scale(1.5,1.5).move(300, 300);

        let g = this.draw.group();
        g.ellipse(50, 100).move(300, 200).fill('#843');
        g.circle(70).move(400, 300).fill("#234");
        let g2 = g.group();
        g2.rect(200,100).move(400,500).fill("#ff3");

        //this.draw.text('ABC').font({size: 122, family: 'Zapfino', leading:  '1.5em'}).move(400, 200);

    }
    mountEvent() {
        if(isMobile()) {
            this.draw.on('touchstart', this.mouseDown.bind(this));
        } else {
            this.draw.mousedown(this.mouseDown.bind(this));
        }
        //Key
        // $(window).on('keyup', (e)=>{
        //     if(e.keyCode === 8 || e.keyCode === 46) {
        //         if(e.target.tagName === 'BODY') {
        //             this.remove();
        //         }
        //
        //     }
        // });
        // $(window).on('keydown', (e) => {
        //     console.log(e);
        //     if ((e.ctrlKey || e.metaKey) && e.which === 67) {
        //         console.log('copy');
        //         this.tf.copy();
        //     }
        // });
    }

    acceptTransform(elm) {
        for (let i of this.noTransform) {
            if(elm.hasClass(i)) {
                return false;
            }
        }
        return true;
    }

    mouseDown(event){
        if(Artboard.Action === 'select') {
            if(!isMobile() && event.buttons !== 1) return;
            let target = event.target.instance ? event.target.instance : SVG(event.target);
            let parents = target.parents('svg');
            if(target.type === 'svg') {
                if(event.shiftKey) {
                    this.tf.removeTool();
                } else {
                    this.tf.setTarget(null);
                }
                if (Artboard.Action === 'select') {
                    this.select(event);
                }
            } else if(parents.length === 1) {
                this.tf.setTarget(target, event);
            }
            else if(parents.length > 0){
                let last = parents[parents.length - 2];
                if(this.acceptTransform(last) && last.node instanceof SVGElement) {
                    this.tf.setTarget(last, event);
                }
            }
        }
        else if(Artboard.Action === "pointer") {
            this.moveViewBox(event);
        }
        else if(Artboard.Action === 'rect') {
            DrawRect(event, this);
        }
        else if(Artboard.Action === 'ellipse') {
            DrawEllipse(event, this);
        }
        else if(Artboard.Action === 'line') {
            DrawLine(event, this);
        }
        else if(Artboard.Action === 'polygon') {
            DrawPolygon(event, this);
        }

    }

    moveViewBox(event) {
        let draw = this.draw;
        let m = draw.node.getScreenCTM().inverse();
        let p1 = draw.node.createSVGPoint();
        p1.x = isMobile() ? event.touches[0].clientX : event.x;
        p1.y = isMobile() ? event.touches[0].clientY : event.y;
        p1 = p1.matrixTransform(m);
        let vb = this.draw.viewbox();
        $(window).off('touchend mouseup');
        $(window).on(isMobile()? "touchmove" : 'mousemove', (e)=>{
            let p2 = draw.node.createSVGPoint();
            p2.x = isMobile() ? e.touches[0].clientX : e.clientX;
            p2.y = isMobile() ? e.touches[0].clientY : e.clientY;
            p2 = p2.matrixTransform(m);
            let dx = p2.x - p1.x;
            let dy = p2.y - p1.y;
            let mx = dx < 0 ? p1.x + dx : p1.x;
            let my = dy < 0 ? p1.y + dy : p1.y;
            this.draw.viewbox(vb.x - dx, vb.y - dy, vb.width, vb.height);
        });
        $(window).on(isMobile() ? "touchend": 'mouseup', ()=>{
            $(window).off('touchmove mousemove');
        });
    }

    select(event) {
        let draw = this.draw;
        let m = draw.node.getScreenCTM().inverse();
        let p1 = draw.node.createSVGPoint();
        p1.x = isMobile() ? event.touches[0].clientX : event.x;
        p1.y = isMobile() ? event.touches[0].clientY : event.y;
        p1 = p1.matrixTransform(m);
        $(window).off('touchend mouseup');
        $(window).on(isMobile()? "touchmove" : 'mousemove', (e)=>{
            this.selectRect = this.selectRect ? this.selectRect : draw.rect().fill("rgba(255,255,255,0)")
                .attr({"vector-effect": "non-scaling-stroke"})
                .stroke({color: 'rgba(27,123,210,0.76)', width: 1, dasharray: "5 5"});
            let p2 = draw.node.createSVGPoint();
            p2.x = isMobile() ? e.touches[0].clientX : e.clientX;
            p2.y = isMobile() ? e.touches[0].clientY : e.clientY;
            p2 = p2.matrixTransform(m);
            let dx = p2.x - p1.x;
            let dy = p2.y - p1.y;
            let mx = dx < 0 ? p1.x + dx : p1.x;
            let my = dy < 0 ? p1.y + dy : p1.y;
            this.selectRect.size(Math.abs(dx), Math.abs(dy)).move(mx, my);
        });
        $(window).on(isMobile() ? "touchend": 'mouseup', ()=>{
            if(this.selectRect) {
                let rbox1 = this.selectRect.rbox(draw);
                this.selectRect.remove();
                this.selectRect = null;

                let children = draw.children();
                let childSelected = [];
                for (let child of children) {
                    let rbox2 = child.rbox(draw);
                    if(checkHit(rbox1, rbox2) && this.acceptTransform(child)) {
                        //child.toParent(this.gSelected);
                        childSelected.push(child);
                    }
                }
                this.tf.setTarget(childSelected, event);
            }
            $(window).off('touchmove mousemove');
        });
    }

    //set========
    resetDrawPolygon() {
        this.drawPolygon = null;
    }

    //HISTORY
    undo(){
    }
    createHistory() {
        //this.tf.removeTool();
        let {w, h } = this.model.bbox();
        //this.draw.viewbox(1, 1, w - 1, h - 1);
        let data = this.draw.svg();
        //this.setZoom(Artboard.Zoom);
        return data;
    }
}


export default Artboard;


















