import Artboard from "../Artboard";
import {isMobile, PointToLocal} from "../core/util";

let DrawLine = (event, artboard)=>{
    let draw = artboard.draw;
    let strokeColor = Artboard.StrokeColor;
    let mouseX = isMobile() ? event.touches[0].clientX : event.clientX;
    let mouseY = isMobile() ? event.touches[0].clientY : event.clientY;

    let p1 = PointToLocal(draw, draw, mouseX, mouseY);
    let line = draw.line()
        //.attr({"vector-effect": "non-scaling-stroke"})
        .opacity(Artboard.Opacity)
        .stroke({color: strokeColor, width: Artboard.StrokeWidth});

    $(window).off('touchend mouseup');
    $(window).on(isMobile()? "touchmove" : 'mousemove', (e)=>{
        let toX = isMobile() ? e.touches[0].clientX : e.clientX;
        let toY = isMobile() ? e.touches[0].clientY : e.clientY;

        let p2 = PointToLocal(draw, draw, toX, toY);
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        if(e.shiftKey) {
            let deg = Math.atan2(dx, dy) * 180 / Math.PI;
            console.log(dx, dy, deg);
            if(deg > 30 && deg < 60) {
                line.plot(p1.x, p1.y, p1.x + dy, p1.y + dy);
            }
            else if(deg > 120 && deg < 150) {
                line.plot(p1.x, p1.y, p1.x - dy, p1.y + dy);
            }
            else if(deg > -150 && deg < -120) {
                line.plot(p1.x, p1.y, p1.x + dy, p1.y + dy);
            }
            else if(deg > - 60 && deg < -30) {
                line.plot(p1.x, p1.y, p1.x - dy, p1.y + dy);
            }
            //===================
            else if((deg >= 60 && deg <= 120) || (deg >= -120 && deg <= -60)) {
                line.plot(p1.x, p1.y, p1.x + dx, p1.y);
            }
            else {
                line.plot(p1.x, p1.y, p1.x, p1.y + dy);
            }
        } else {
            line.plot(p1.x, p1.y, p2.x, p2.y);
        }

    });
    $(window).on(isMobile() ? "touchend": 'mouseup', ()=>{
        if (line.width() < 1 && line.height() < 1) {
            line.remove();
        }
        $(window).trigger('layer');
        $(window).off('touchmove mousemove');
    })
    artboard.tf.refreshModel();
}
export {DrawLine}