export default [
    {
        name : 't_tl',
        p : (x, y, w, h) => {
            return {
                x : x,
                y: y
            }
        }
    },
    {
        name : 't_tr',
        p : (x, y, w, h) => {
            return {
                x : x + w,
                y: y
            }
        }
    },
    {
        name : 't_br',
        p : (x, y, w, h) => {
            return {
                x : x + w,
                y: y + h
            }
        }
    },
    {
        name : 't_bl',
        p : (x, y, w, h) => {
            return {
                x : x,
                y: y + h
            }
        }
    },
    {
        name : 't_ct',
        p : (x, y, w, h) => {
            return {
                x : x + w/2,
                y: y
            }
        }
    },
    {
        name : 't_cr',
        p : (x, y, w, h) => {
            return {
                x : x + w,
                y: y + h/2
            }
        }
    },
    {
        name : 't_cb',
        p : (x, y, w, h) => {
            return {
                x : x + w/2,
                y: y + h
            }
        }
    },
    {
        name : 't_cl',
        p : (x, y, w, h) => {
            return {
                x : x,
                y: y + h/2
            }
        }
    }
    // {
    //     name : 't_rtl',
    //     p : (x, y, w, h) => {
    //         return {
    //             x : x - 10,
    //             y: y - 10
    //         }
    //     }
    // },
    // {
    //     name : 't_rtr',
    //     p : (x, y, w, h) => {
    //         return {
    //             x : x + w,
    //             y: y
    //         }
    //     }
    // },
    // {
    //     name : 't_rbr',
    //     p : (x, y, w, h) => {
    //         return {
    //             x : x + w,
    //             y: y + h
    //         }
    //     }
    // },
    // {
    //     name : 't_rbl',
    //     p : (x, y, w, h) => {
    //         return {
    //             x : x,
    //             y: y + h
    //         }
    //     }
    // },
]