function isSafariMobile() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /iPhone|iPad|iPod/.test(navigator.userAgent);
}

let isMobile = ()=>{

    const _isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    return _isMobile
}



//Chuyen 1 diem tu screen to local
let PointToLocal = (target, from, x, y)=>{
    let ma = target.node.getScreenCTM().inverse();
    let p = from.node.createSVGPoint();
    p.x = x;
    p.y = y;
    p = p.matrixTransform(ma);
    return p;
}

export {isMobile, PointToLocal, isSafariMobile}
