import Artboard from "../Artboard";
import {isMobile, PointToLocal} from "../core/util";

let DrawEllipse = (event, artboard)=>{
    let draw = artboard.draw;
    let fillColor = Artboard.FillColor;
    let strokeColor = Artboard.StrokeColor;

    let mouseX = isMobile() ? event.touches[0].clientX : event.clientX;
    let mouseY = isMobile() ? event.touches[0].clientY : event.clientY;

    let p1 = PointToLocal(draw, draw, mouseX, mouseY);

    let ellipse = draw.ellipse()
        .fill(fillColor)
        .attr({"vector-effect": "non-scaling-stroke"})
        .opacity(Artboard.Opacity)
        .stroke({color: strokeColor, width: Artboard.StrokeWidth});


    $(window).off('touchend mouseup');
    $(window).on(isMobile()? "touchmove" : 'mousemove', (e)=>{
        let toX = isMobile() ? e.touches[0].clientX : e.clientX;
        let toY = isMobile() ? e.touches[0].clientY : e.clientY;
        let p2 = PointToLocal(draw, draw, toX, toY);
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        if(e.shiftKey) {
            ellipse.size(Math.abs(dy) * 2, Math.abs(dy) * 2).center(p1.x, p1.y);
        } else {
            ellipse.size(Math.abs(dx) * 2, Math.abs(dy) * 2).center(p1.x, p1.y);
        }

    });
    $(window).on(isMobile() ? "touchend": 'mouseup', ()=>{
        if (ellipse.width() < 1) {
            ellipse.remove();
        }
        $(window).trigger('layer');
        $(window).off('touchmove mousemove');
    })

    artboard.tf.refreshModel();

}

export {DrawEllipse}