import Artboard from "../Artboard";
import {isMobile, PointToLocal} from "../core/util";

let dot;

let DrawPolygon = (event, artboard)=>{
    let draw = artboard.draw;
    let fillColor = Artboard.FillColor;
    let strokeColor = Artboard.StrokeColor;
    let x = isMobile() ? event.touches[0].clientX : event.clientX;
    let y = isMobile() ? event.touches[0].clientY : event.clientY;
    let p1 = PointToLocal(draw, draw, x, y);

    if (artboard.drawPolygon) {
        let polygon = artboard.drawPolygon;
        let pointArray = polygon.array();
        pointArray.push([p1.x, p1.y]);
        polygon.plot(pointArray);
        if(dot) dot.remove();
        $(window).trigger('layer');
    } else {
        artboard.drawPolygon = draw.polygon([p1.x, p1.y])
            .fill(fillColor)
            .attr({"vector-effect": "non-scaling-stroke"})
            .opacity(Artboard.Opacity)
            .stroke({color: strokeColor, width: Artboard.StrokeWidth});
        dot = draw.circle(2).center(p1.x, p1.y);
        artboard.tf.refreshModel();
    }
}

export {DrawPolygon}