import {Matrix} from "@svgdotjs/svg.js";


let pointToLocal = (draw, target, x, y)=>{
    let ma = target.node.getScreenCTM().inverse();
    let p = draw.node.createSVGPoint();
    p.x = x;
    p.y = y;
    p = p.matrixTransform(ma);
    return p;
}

let alignLeft = (draw, gWrap, target, {ratioW, ratioH})=>{
    if(target && gWrap) {
        let gSelected = gWrap.findOne('.g-selected');
        if(gSelected) {
            let rbg = gSelected.rbox(draw);
            let bbg = gSelected.bbox();
            gSelected.each((i, childrens)=>{
                let child = childrens[i];
                let rbc = child.rbox(draw);
                let bbc = child.bbox();

                let tx = rbg.x;
                let ty = rbc.y;

                let m1 = child.matrix();

                let p1 = pointToLocal(draw, child, rbc.x, rbc.y);
                let p2 = pointToLocal(draw, child, tx, ty);

                let dx = p2.x - p1.x;
                let dy = p2.y - p1.y;

                let m2 = new Matrix({translate: [dx / ratioW, dy / ratioH], ox: bbc.x, oy: bbc.y});
                let m3 = m1.multiply(m2);
                child.transform(m3);
            })
        }
    }
}

let alignLeftArtboard = (draw, {ratioW, ratioH})=>{
    let model = draw.findOne(".model");
    let gWrap = draw.findOne(".transform-wrap");
    if(gWrap && model) {
        let rbWrap = gWrap.rbox(draw);
        let rbModel = model.rbox(draw);
        let bbWrap = gWrap.bbox();
        let tx = rbModel.x;
        let ty = rbWrap.y;
        let m1 = gWrap.matrix();
        let p1 = pointToLocal(draw, gWrap, rbWrap.x, rbWrap.y);
        let p2 = pointToLocal(draw, gWrap, tx, ty);
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        let m2 = new Matrix({translate: [dx / ratioW, dy/ ratioH], ox: bbWrap.x, oy: bbWrap.y});
        let m3 = m1.multiply(m2);
        gWrap.transform(m3);
    }
}

let alignRight = (draw, gWrap, target, {ratioW, ratioH})=>{
    if(target && gWrap) {
        let gSelected = gWrap.findOne('.g-selected');
        if(gSelected) {
            let rbg = gSelected.rbox(draw);
            let bbg = gSelected.bbox();
            gSelected.each((i, childrens)=>{
                let child = childrens[i];
                let rbc = child.rbox(draw);
                let bbc = child.bbox();

                let tx = rbg.x2 - rbc.w;
                let ty = rbc.y;

                let m1 = child.matrix();

                let p1 = pointToLocal(draw, child, rbc.x, rbc.y);
                let p2 = pointToLocal(draw, child, tx, ty);

                let dx = p2.x - p1.x;
                let dy = p2.y - p1.y;

                let m2 = new Matrix({translate: [dx / ratioW, dy / ratioH], ox: bbc.x, oy: bbc.y});
                let m3 = m1.multiply(m2);
                child.transform(m3);
            })
        }
    }
}

let alignRightArtboard = (draw, {ratioW, ratioH})=>{
    let model = draw.findOne(".model");
    let gWrap = draw.findOne(".transform-wrap");
    if(gWrap && model) {
        let rbWrap = gWrap.rbox(draw);
        let rbModel = model.rbox(draw);
        let bbWrap = gWrap.bbox();
        let tx = rbModel.x2;
        let ty = rbWrap.y;
        let m1 = gWrap.matrix();
        let p1 = pointToLocal(draw, gWrap, rbWrap.x2, rbWrap.y);
        let p2 = pointToLocal(draw, gWrap, tx, ty);
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        let m2 = new Matrix({translate: [dx / ratioW, dy/ ratioH], ox: bbWrap.x2, oy: bbWrap.y});
        let m3 = m1.multiply(m2);
        gWrap.transform(m3);
    }
}


let alignTop = (draw, gWrap, target, {ratioW, ratioH})=>{
    if(target && gWrap) {
        let gSelected = gWrap.findOne('.g-selected');
        if(gSelected) {
            let rbg = gSelected.rbox(draw);
            let bbg = gSelected.bbox();
            gSelected.each((i, childrens)=>{
                let child = childrens[i];
                let rbc = child.rbox(draw);
                let bbc = child.bbox();

                let tx = rbc.x;
                let ty = rbg.y;

                let m1 = child.matrix();

                let p1 = pointToLocal(draw, child, rbc.x, rbc.y);
                let p2 = pointToLocal(draw, child, tx, ty);

                let dx = p2.x - p1.x;
                let dy = p2.y - p1.y;

                let m2 = new Matrix({translate: [dx / ratioW, dy / ratioH], ox: bbc.x, oy: bbc.y});
                let m3 = m1.multiply(m2);
                child.transform(m3);
            })
        }
    }
}

let alignTopArtboard = (draw, {ratioW, ratioH})=>{
    let model = draw.findOne(".model");
    let gWrap = draw.findOne(".transform-wrap");
    if(gWrap && model) {
        let rbWrap = gWrap.rbox(draw);
        let rbModel = model.rbox(draw);
        let bbWrap = gWrap.bbox();
        let tx = rbWrap.x;
        let ty = rbModel.y;
        let m1 = gWrap.matrix();
        let p1 = pointToLocal(draw, gWrap, rbWrap.x, rbWrap.y);
        let p2 = pointToLocal(draw, gWrap, tx, ty);
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        let m2 = new Matrix({translate: [dx / ratioW, dy/ ratioH], ox: bbWrap.x2, oy: bbWrap.y});
        let m3 = m1.multiply(m2);
        gWrap.transform(m3);
    }
}

let alignBottom = (draw, gWrap, target, {ratioW, ratioH})=>{
    if(target && gWrap) {
        let gSelected = gWrap.findOne('.g-selected');
        if(gSelected) {
            let rbg = gSelected.rbox(draw);
            let bbg = gSelected.bbox();
            gSelected.each((i, childrens)=>{
                let child = childrens[i];
                let rbc = child.rbox(draw);
                let bbc = child.bbox();

                let tx = rbc.x;
                let ty = rbg.y2 - rbc.h;

                let m1 = child.matrix();

                let p1 = pointToLocal(draw, child, rbc.x, rbc.y);
                let p2 = pointToLocal(draw, child, tx, ty);

                let dx = p2.x - p1.x;
                let dy = p2.y - p1.y;

                let m2 = new Matrix({translate: [dx / ratioW, dy / ratioH], ox: bbc.x, oy: bbc.y});
                let m3 = m1.multiply(m2);
                child.transform(m3);
            })
        }
    }
}

let alignBottomArtboard = (draw, {ratioW, ratioH})=>{
    let model = draw.findOne(".model");
    let gWrap = draw.findOne(".transform-wrap");
    if(gWrap && model) {
        let rbWrap = gWrap.rbox(draw);
        let rbModel = model.rbox(draw);
        let bbWrap = gWrap.bbox();
        let tx = rbWrap.x;
        let ty = rbModel.y2;
        let m1 = gWrap.matrix();
        let p1 = pointToLocal(draw, gWrap, rbWrap.x, rbWrap.y2);
        let p2 = pointToLocal(draw, gWrap, tx, ty);
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        let m2 = new Matrix({translate: [dx / ratioW, dy/ ratioH], ox: bbWrap.x2, oy: bbWrap.y});
        let m3 = m1.multiply(m2);
        gWrap.transform(m3);
    }
}


let alignH = (draw, gWrap, target, {ratioW, ratioH})=>{
    if(target && gWrap) {
        let gSelected = gWrap.findOne('.g-selected');
        if(gSelected) {
            let rbg = gSelected.rbox(draw);
            let bbg = gSelected.bbox();
            gSelected.each((i, childrens)=>{
                let child = childrens[i];
                let rbc = child.rbox(draw);
                let bbc = child.bbox();

                let tx = rbc.x;
                let ty = rbg.cy - rbc.h/2;

                let m1 = child.matrix();

                let p1 = pointToLocal(draw, child, rbc.x, rbc.y);
                let p2 = pointToLocal(draw, child, tx, ty);

                let dx = p2.x - p1.x;
                let dy = p2.y - p1.y;

                let m2 = new Matrix({translate: [dx / ratioW, dy / ratioH], ox: bbc.x, oy: bbc.y});
                let m3 = m1.multiply(m2);
                child.transform(m3);
            })
        }
    }
}

let alignHArtboard = (draw, {ratioW, ratioH})=>{
    let model = draw.findOne(".model");
    let gWrap = draw.findOne(".transform-wrap");
    if(gWrap && model) {
        let rbWrap = gWrap.rbox(draw);
        let rbModel = model.rbox(draw);
        let bbWrap = gWrap.bbox();
        let tx = rbWrap.cx;
        let ty = rbModel.cy;
        let m1 = gWrap.matrix();
        let p1 = pointToLocal(draw, gWrap, rbWrap.cx, rbWrap.cy);
        let p2 = pointToLocal(draw, gWrap, tx, ty);
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        let m2 = new Matrix({translate: [dx / ratioW, dy/ ratioH], ox: bbWrap.cx, oy: bbWrap.cy});
        let m3 = m1.multiply(m2);
        gWrap.transform(m3);
    }
}

let alignVArtboard = (draw, {ratioW, ratioH})=>{
    let model = draw.findOne(".model");
    let gWrap = draw.findOne(".transform-wrap");
    if(gWrap && model) {
        let rbWrap = gWrap.rbox(draw);
        let rbModel = model.rbox(draw);
        let bbWrap = gWrap.bbox();
        let tx = rbModel.cx;
        let ty = rbWrap.cy;
        let m1 = gWrap.matrix();
        let p1 = pointToLocal(draw, gWrap, rbWrap.cx, rbWrap.cy);
        let p2 = pointToLocal(draw, gWrap, tx, ty);
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        let m2 = new Matrix({translate: [dx / ratioW, dy/ ratioH], ox: bbWrap.cx, oy: bbWrap.cy});
        let m3 = m1.multiply(m2);
        gWrap.transform(m3);
    }
}


let alignV = (draw, gWrap, target, {ratioW, ratioH})=>{
    if(target && gWrap) {
        let gSelected = gWrap.findOne('.g-selected');
        if(gSelected) {
            let rbg = gSelected.rbox(draw);
            let bbg = gSelected.bbox();
            gSelected.each((i, childrens)=>{
                let child = childrens[i];
                let rbc = child.rbox(draw);
                let bbc = child.bbox();

                let tx = rbg.cx - rbc.w / 2;
                let ty = rbc.y;

                let m1 = child.matrix();

                let p1 = pointToLocal(draw, child, rbc.x, rbc.y);
                let p2 = pointToLocal(draw, child, tx, ty);

                let dx = p2.x - p1.x;
                let dy = p2.y - p1.y;

                let m2 = new Matrix({translate: [dx / ratioW, dy / ratioH], ox: bbc.x, oy: bbc.y});
                let m3 = m1.multiply(m2);
                child.transform(m3);
            })
        }
    }
}



export {alignLeft, alignRight, alignTop, alignBottom, alignH, alignV,
    alignLeftArtboard, alignRightArtboard, alignTopArtboard, alignBottomArtboard, alignHArtboard, alignVArtboard}