
export default [
    {
        deg: 0,
        scale: 'ew-resize',
        rotate: import('../assets/img/0.png'),
        ox: 0,
        oy: 8
        
    },
    {
        deg: 45,
        scale: 'nwse-resize',
        rotate: import('../assets/img/45.png'),
        ox: 8,
        oy: 8
    },
    {
        deg: 90,
        scale: 'ns-resize',
        rotate: import('../assets/img/90.png'),
        ox: 8,
        oy: 0
    },
    {
        deg: 135,
        scale: 'nesw-resize',
        rotate: import('../assets/img/135.png'),
        ox: 8,
        oy: 8
    },
    {
        deg: 180,
        scale: 'ew-resize',
        rotate: import('../assets/img/180.png'),
        ox: 8,
        oy: 8
    },
    {
        deg: 225,
        scale: 'nwse-resize',
        rotate: import('../assets/img/225.png'),
        ox: 8,
        oy: 8
    },
    {
        deg: 270,
        scale: 'ns-resize',
        rotate: import('../assets/img/270.png'),
        ox: 8,
        oy: 8
    },
    {
        deg: 315,
        scale: 'nesw-resize',
        rotate: import('../assets/img/315.png'),
        ox: 8,
        oy: 8
    }
]